import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import {Route, Routes, Navigate, useLocation} from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import Login from './pages/auth/Login';
import MultiFactorAuthentication from './pages/auth/MultiFactorAuthentication';
import RecoverPassword from './pages/auth/RecoverPassword';
import RegistrationStage1 from './pages/auth/RegistrationStage1';
import RegistrationStage2 from './pages/auth/RegistrationStage2';
import RegistrationStage3 from './pages/auth/RegistrationStage3';

// import Settings from './pages/auth/Settings';
import Talents from './pages/userPages/Talents';
import FaqPage from './pages/userPages/FaqPage';
import OpenPositions from './pages/userPages/OpenPositions';
import NewOpenPosition from './pages/userPages/NewOpenPosition';
import HiringProcess from './pages/userPages/HiringProcess';
import SalesTeamReview from './pages/userPages/SalesTeamReview';
import TeamAnalytics from './pages/userPages/TeamAnalytics';
import PoolAnalytics from './pages/userPages/PoolAnalytics';
import TeamReview from './pages/userPages/TeamReview';
import Messages from './pages/userPages/Messages';
import Billing from './pages/userPages/Billing';
//import CompetencyModel from './pages/userPages/CompetencyModel'
// import HelpCenter from './pages/userPages/HelpCenter';
import ErrorPage from './pages/errorPage/ErrorPage';
import Onboarding from './pages/userPages/Onboarding';
import SetPassword from './pages/userPages/SetPassword';
import TeamSimulatorPage from './pages/userPages/TeamSimulatorPage';
import Sidebar from './components/layoutComponents/navigation/Sidebar';
import MobileNav from './components/layoutComponents/navigation/MobileNav';
import * as Constants from './constants'
import UserRoles from "./pages/auth/userRoles.jsx"
import {fetchContentfulData} from './store/actions/contentfulDataActions';
import {useSelector, useDispatch} from 'react-redux';
import {useEffect, useMemo} from "react";

import { ThemeProvider } from '@mui/material';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

import 'react-toastify/dist/ReactToastify.css';
import B2BApiService from "./B2bApiService";
import { MUITheme } from './MUITheme';

import { logPageView } from './analytics';

function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const companyData = useSelector(state => state.company.info);
    const companyIsActive = useSelector(state => state.company.isActive);
    const authenticationData = JSON.parse(localStorage.getItem(Constants.MAZHR_JWT_KEY)) || null;
    let isAuthenticated = false;
    let isMFARequired = false;

    const isProduction = process.env.NODE_ENV === 'production';
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    useEffect(() => {
        logPageView();
        dispatch(fetchContentfulData());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated && !companyData.name) {
            b2bServiceObj.LoadCompanyData();
        }
        // eslint-disable-next-line
    }, [isAuthenticated, companyData.name, b2bServiceObj]);

    if (authenticationData && authenticationData?.access_token) {
        const decodedJWT = jwtDecode(authenticationData?.access_token?.toString());
        let company_enforce_mfa, mfa_activated, mfa_verified;
        // For integrity check, if JWT is decoded, use the values from JWT
        if (decodedJWT) {
            company_enforce_mfa = decodedJWT.company_enforce_mfa;
            mfa_activated = decodedJWT.mfa_activated;
            mfa_verified = decodedJWT.mfa_verified;
        } else { // Otherwise, use the values from local storage
            company_enforce_mfa = authenticationData.company_enforce_mfa;
            mfa_activated = authenticationData.mfa_activated;
            mfa_verified = authenticationData.mfa_verified;
        }
        
        /* Case: MFA is not activated and verified 
         * Case: company does not enforce MFA and MFA is not activated
         * => no MFA required + access_token available => authenticated
         */ 
        if ((mfa_activated && mfa_verified) || (!company_enforce_mfa && !mfa_activated)) {
            isMFARequired = false;
            isAuthenticated = true;
        } else {
            /* Other cases
            * - Company enforces MFA and MFA is not activated
            * - Company enforces MFA and MFA is activated but not verified
            * - Company doesnot enforce MFA and MFA is activated but not verified
            * => MFA required + access_token available => not authenticated 
            */ 
            isMFARequired = true;
            isAuthenticated = false;
        }
    }

    // Define an array of restricted paths for logged in user
    const restrictedPaths = [
        '/mfa',
        '/register',
        '/register-2',
        '/register-3',
        '/set-password',
        '/recover-password'
    ];
    // Redirect authenticated users from restricted paths
    if (isAuthenticated && restrictedPaths.includes(location.pathname)) {
        return <Navigate to="/dashboard" replace/>;
    }

    return (
        <CacheBuster
            currentVersion={packageInfo.version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
        >
            <ThemeProvider theme={MUITheme}>
                <div className="App">
                    <div className="page">
                        {(isAuthenticated
                            && location.pathname !== "/recover-password"
                            && location.pathname !== "/terms-conditions")
                        &&
                        <>
                            <Sidebar/>
                            <MobileNav/>
                        </>
                        &&
                        <>
                            {window.innerWidth > 992 ?
                                <Sidebar/>
                                :
                                <MobileNav/>
                            }
                        </>
                        }
                        <Routes>
                            {isAuthenticated && companyIsActive && <Route path="/" element={<Onboarding/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="/dashboard" element={<Onboarding/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="open-positions" element={<OpenPositions/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="open-positions/:hash" element={<OpenPositions/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="hiring-process" element={<HiringProcess/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="new-open-position" element={<NewOpenPosition/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="talents" element={<Talents/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="team-simulator" element={<TeamSimulatorPage/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="sales-team-review" element={<SalesTeamReview/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="team-analytics" element={<TeamAnalytics/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="candidate-analytics" element={<PoolAnalytics/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="team-review" element={<TeamReview/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="messages" element={<Messages/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="billing" element={<Billing/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="talents/:hashParam" element={<Talents/>}/>}
                            {isAuthenticated && <Route path="faq-page" element={<FaqPage />} />}
                            {isAuthenticated && <Route path="settings" element={<UserRoles/>}/>}
                            {isAuthenticated && <Route path="*" element={<Navigate to="/dashboard" />}/>}
                            
                            <Route path="register" element={<RegistrationStage1/>}/>
                            <Route path="register-2" element={<RegistrationStage2/>}/>
                            <Route path="register-3" element={<RegistrationStage3/>}/>
                            <Route path="set-password" element={<SetPassword/>}/>
                            <Route path="recover-password" element={<RecoverPassword/>}/>
                            {authenticationData?.access_token && isMFARequired ? 
                                // If MFA is required after password authentication, redirect to MFA page
                                // If MFA is not required after password authentication, all authenticated routes above are available
                                <> 
                                    <Route path="mfa" element={<MultiFactorAuthentication/>}/>
                                    <Route path="*" element={<Navigate to="/mfa" />}/>
                                </> : 
                                // MFA is not required or no password authentication
                                <>
                                    <Route path="/" element={<Login/>}/>                            
                                    <Route path="login" element={<Login/>}/>
                                    <Route path="*" element={<ErrorPage/>}/>
                                </>
                            }
                        </Routes>
                    </div>
                </div>
            </ThemeProvider>
        </CacheBuster>
    );
}

export default App;
