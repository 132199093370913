import { useMemo, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import * as Constants from "../../constants";
import B2BApiService from "../../B2bApiService";
import Logout from '../../components/layoutComponents/navigation/Logout';
import PageLayout from "../../components/layoutComponents/PageLayout";
import InputError from "../../components/formComponents/InputError";
import apexLogo from "../../assets/images/logos/apex_analytics_green_logo.svg";
import checkGreen from '../../assets/images/icons/checkGreen.svg';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { TextField } from '@mui/material';

/**
 * TODO:
 * - IMPORTANT: Move company_enforce_mfa, mfa_activated, mfa_verified away from localStorage to avoid manual adjustment from users/hackers
 * - Allow users to manually activate, deactivate MFA (unless enforced by company)
 * - Replace hardcoded text with Contentful dynamic content (with translation to German, later)
 * - Add loading spinner when activating MFA
 * - Add loading spinner when verifying MFA code
 */
function MultiFactorAuthentication() {

    const authenticationData = JSON.parse(localStorage.getItem(Constants.MAZHR_JWT_KEY)) || null;

    const [step, setStep] = useState(1); // Tracks the current step in the MFA process
    const [mfaData, setMfaData] = useState(null);
    const [activationError, setActivationError] = useState(null);
    const [inOnMFASetup, setInOnMFASetup] = useState(false); // Tracks if the user is in the MFA setup process
    const [authCode, setAuthCode] = useState('');
    const [verificationError, setVerificationError] = useState(null);
    const [countdown, setCountdown] = useState(3);  // After vertification succeeded, countdown from 3 seconds before refirecting to dashboard 

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    useEffect(() => {
        // Unless user is in the MFA setup process, check if MFA is already activated but not verified
        if (!inOnMFASetup) {
            // For integrity check, if decoded JWT is available, use the values from JWT
            if (authenticationData?.access_token) {
                const decodedJWT = jwtDecode(authenticationData?.access_token?.toString());
                const mfa_activated = decodedJWT?.mfa_activated;
                const mfa_verified = decodedJWT?.mfa_verified;
                if (mfa_activated && !mfa_verified) {
                    setStep(3); // Skip to step 3 if MFA is already activated but not yet verified
                }
            // If the JWT is not available, check the localStorage values
            } else if (authenticationData?.mfa_activated && !authenticationData?.mfa_verified) {
                setStep(3); // Skip to step 3 if MFA is already activated but not yet verified
            }
        }
    }, [inOnMFASetup, authenticationData, step]);

    useEffect(() => {
        // When the screen is on step 4, start the countdown and redirect to the dashboard when it reaches 0
        if (step === 4) {
            if (countdown > 0) {
                const timer = setInterval(() => {
                    setCountdown(prevCountdown => prevCountdown - 1);
                }, 1000);
                return () => clearInterval(timer);
            } else {
                window.location.href = '/';
            }
        }
    }, [step, countdown]);

    const activateMFA = () => {
        b2bServiceObj.ActivateMFA((response) => {
            if (response.httpStatus === 200) {
                setStep(2); // Moving to MFA setup process
                setInOnMFASetup(true); // Avoid the app to automatically move to step 3 (verification) with users that activated and setup already
                setMfaData(response?.json);
                const new_access_token = response?.json?.access_token;
                const new_refresh_token = response?.json?.refresh_token;
                if (authenticationData && new_access_token && new_refresh_token) {
                    localStorage.setItem(Constants.MAZHR_JWT_KEY, JSON.stringify({ 
                        ...authenticationData,
                        access_token: new_access_token,
                        refresh_token: new_refresh_token,
                        mfa_activated: true
                    }));
                }
                setActivationError(null);
            } else {
                setActivationError('Error activating MFA. Please try again later, or contact our support at security@apexteam.ai');
            }
        });
    };

    const completeSetup = () => {
        setInOnMFASetup(false);
        setStep(3);
    };

    const handleVerificationChange = (e) => {
        const value = e.target.value;
        setAuthCode(value);
    
        // Remove error if user starts typing
        if (value.trim() === '') {
          setVerificationError('Code cannot be empty');
        } else {
          setVerificationError(null);
        }
    };

    // Handle Enter key press when users verify the MFA code
    const handleVerificationKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();  // Prevent form submission or default behavior
          verifyAuthCode();
        }
    };

    const verifyAuthCode = () => {
        if (authCode && authCode !== '') {
            const data = {
                mfa_code: authCode
            };
            b2bServiceObj.VerifyMFA(data, (response) => {
                if (response.httpStatus === 200) {
                    // If successful, update the JWT with the new authentication data
                    const { access_token, refresh_token, company_enforce_mfa, mfa_activated, mfa_verified } = response.json;
                    if (authenticationData && access_token && refresh_token) {
                        localStorage.setItem(Constants.MAZHR_JWT_KEY, JSON.stringify({ 
                            ...authenticationData, 
                            access_token,
                            refresh_token,   
                            company_enforce_mfa,
                            mfa_activated,
                            mfa_verified }));
                    } 
                    setVerificationError(null);
                    setStep(4);
                } else {
                    setVerificationError('Incorrect code. Please try again.');
                }
            });
        } else {
            setVerificationError('Code cannot be empty');
        }
    };

    return (   
        <PageLayout>
            <div 
                className="center-content" 
                style={{
                    minHeight: '100vh',
                    height: 'fit-content',
                    justifyContent: 'unset',
                    textAlign: 'center',
                }}
            >               
                <div className="mar-25 top">
                    <div className='mar-25 bottom'><img className='company-logo' src={apexLogo} height={60} alt="Apex Analytics" /></div>
                    {step === 1 && (
                        <>
                        <h2 className="title semi-bold l mar-15 bottom">Multi-Factor Authentication</h2>
                        <p>Your organization requires multi-factor authentication for all users.<br/>
                            Click below to activate MFA for added security.
                        </p>
                        <button className="mazhrBtn default-green mar-20 top full-width" onClick={activateMFA}>
                            Activate Multi-Factor Authentication
                        </button>
                        {activationError && <InputError errorMessage={activationError} />}
                        </>
                    )}

                    {step === 2 && (
                        <>
                        <h2>Installation and Setup</h2>
                        <p>Please install a mobile authenticator app of your choice.<br/>We recommend one of the following:</p>
                        <p><b>
                            Google Authenticator for <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer"> Android </a> or 
                            <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer"> iOS</a>
                        </b></p>
                        <p><b>
                            Microsoft Authenticator for <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank" rel="noopener noreferrer"> Android</a> or 
                            <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank" rel="noopener noreferrer"> iOS</a>
                        </b></p>
                        <p><b>
                            Authy Authenticator for <a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank" rel="noopener noreferrer">Android</a> or 
                            <a href="https://apps.apple.com/us/app/authy/id494168017" target="_blank" rel="noopener noreferrer"> iOS</a>
                        </b></p>
                        
                        <p>
                            Open the app and look for an option similar to <strong>"Set up an account"</strong> or <strong>"Scan a QR code"</strong>.<br/>
                            Hold your phone in front of the QR code below and scan it.<br/>
                            <strong>Important:</strong> Do <strong>not</strong> use your phone’s camera app to scan the QR code. <br/>
                            You must use the <strong>Authenticator app</strong> to scan the code.
                        </p>
                        
                        {mfaData?.qr_code ? 
                            <img src={`data:image/png;base64,${mfaData?.qr_code}`} alt="QR Code" height={400}/> : 
                            <InputError errorMessage="There was an error generating QR code. Please try again later." />
                        }

                        {mfaData?.mfa_secret && (
                            <p>Or enter this Setup Key manually: <strong>{mfaData?.mfa_secret}</strong></p>
                        )}

                        <p>
                            <span style={{color: 'red'}}>For security reasons, we will not show these code again.<br/>
                            Donot close or refresh this page until you have completed the setup.<br/>
                            If you need to leave, save the QR Code or Setup Key in a secure place, and complete the setup later.
                            <br/></span>
                            If you have any issue, please contact our support at security@apexteam.ai
                        </p>

                        <button className="mazhrBtn default-green mar-20 top full-width" onClick={completeSetup}>I have done the setup</button>
                        </>
                    )}

                    {step === 3 && (
                        <>
                        <h2>Multi-Factor Verification</h2>
                        <p>
                            Your account is protected with multi-factor authentication.<br/>
                            Please enter the code from your authenticator app.<br/>
                            If you have any issue, please contact our support at security@apexteam.ai
                        </p>
                        <TextField
                            fullWidth
                            placeholder="Enter the code here..."
                            label="Authentication code" 
                            variant="standard"
                            name="authCode"
                            type="authCode"
                            autoComplete="on"
                            value={authCode}
                            inputProps={{style: { textAlign: 'center' }}}
                            onKeyDown={handleVerificationKeyDown}
                            onChange={handleVerificationChange}
                        />
                        <button className="mazhrBtn default-green mar-20 top full-width" onClick={verifyAuthCode}>Verify</button>
                        {verificationError && <InputError errorMessage={verificationError} />}
                        <Logout>
                            <button className="mazhrBtn default-pink mar-20 top full-width" 
                                onClick={() => window.location.href = `mailto:security@apexteam.ai`} >
                                Contact our support at security@apexteam.ai
                            </button>
                        </Logout>
                        </>
                    )}

                    {step === 4 && (
                        <>
                        <div>
                            {CheckCircleOutlinedIcon 
                                ? <CheckCircleOutlinedIcon style={{ color: 'green', fontSize: 100 }} /> 
                                : <img src={checkGreen || ''} height={60} alt="verification_success" />
                            }
                        </div>
                        <h2>Verification succeeded!</h2>
                        <p>Redirecting to the dashboard in {countdown} seconds...</p>
                        </>
                    )}
                </div>
            </div>
        </PageLayout>
    );
}

export default MultiFactorAuthentication;


