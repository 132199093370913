

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

export function CommonBehaviours ({ commonBehaviours, missingBehaviours }) {
  const [topCommonBehaviours, setTopCommonBehaviours] = useState()
  const [topMissingBehaviours, setTopMissingBehaviours] = useState()
  useEffect(()=> {
   setTopCommonBehaviours(commonBehaviours.sort((a,b) => b.average-a.average).slice(0,4))
  },[commonBehaviours])
  useEffect(()=> {
    setTopMissingBehaviours(missingBehaviours.sort((a,b) => b.average-a.average).slice(0,4))
  },[missingBehaviours])

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      }
    },
    scales: {
        x: {
        beginAtZero: true,
        min: 1,
        max: 9,
        ticks: {
          font: { size: 14 },
        },
      },
      y: {
        ticks: {
          font: { size: 14 },
          crossAlign: "far",
        },
      }
    }
  };

  const labels = [];
  let newData = [];
  topCommonBehaviours?.length > 0 && topCommonBehaviours
    .sort((a, b) => b.average - a.average)
    .forEach((data) => {
      labels.push(data.column_name);
      newData.push(data.average);
    });
    topMissingBehaviours?.length > 0 && topMissingBehaviours
    .sort((a, b) => b.average - a.average)
    .forEach((data) => {
      labels.push(data.column_name);
      newData.push(data.average);
    });

  const data = {
    labels,
    datasets: [
      {
        categoryPercentage: 1.0,
        barPercentage: 1.0,
        barThickness: 15,
        data: newData,
        backgroundColor: ['#00B0A0','#00B0A0','#00B0A0','#00B0A0','#8049B0','#8049B0','#8049B0','#8049B0']
      }
    ],
  };

  return (
    <Bar data={data} options={options} />
  )
}

export function CommonCompetencies ({ commonComp, missingComp }) {
  const [topCommonComp, setTopCommonComp] = useState()
  const [topMissingComp, setTopMissingComp] = useState()
  useEffect(()=> {
   setTopCommonComp(commonComp.sort((a,b) => b.average-a.average).slice(0,4))
  },[commonComp])
  useEffect(()=> {
    setTopMissingComp(missingComp.sort((a,b) => b.average-a.average).slice(0,4))
  },[missingComp])

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      }
    },
    scales: {
        x: {
        beginAtZero: true,
        min: 1,
        max: 9,
        ticks: {
          font: { size: 14 },
        },
      },
      y: {
        ticks: {
          font: { size: 14 },
          crossAlign: "far",
        },
      }
    }
  };

  const labels = [];
  let newData = [];
  topCommonComp?.length > 0 && topCommonComp
    .sort((a, b) => b.average - a.average)
    .forEach((data) => {
      labels.push(data.column_name);
      newData.push(data.average);
    });
    topMissingComp?.length > 0 && topMissingComp
    .sort((a, b) => b.average - a.average)
    .forEach((data) => {
      labels.push(data.column_name);
      newData.push(data.average);
    });

  const data = {
    labels,
    datasets: [
      {
        categoryPercentage: 1.0,
        barPercentage: 1.0,
        barThickness: 15,
        data: newData,
        backgroundColor: ['#00B0A0','#00B0A0','#00B0A0','#00B0A0','#8049B0','#8049B0','#8049B0','#8049B0']
      }
    ],
  };

  return (
    <Bar data={data} options={options} />
  )
}

export function TeamStrengthsChart ({ teamStrengthData }) {


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      }
    },
    scales: {
      r: {
        min: 0,
        max: 8,
        ticks: {
          stepSize: 1, // the number of step
        },
        grid: {
          circular: true
        },
        beginAtZero: true
      }
    }
  };
  let roundedData = []
  if (teamStrengthData && teamStrengthData.length > 0) {
    roundedData = [teamStrengthData[0]?.average, teamStrengthData[1]?.average,
      teamStrengthData[2]?.average, teamStrengthData[3]?.average,
      teamStrengthData[4]?.average, teamStrengthData[5]?.average,
      teamStrengthData[6]?.average, teamStrengthData[7]?.average, teamStrengthData[8]?.average]

    roundedData = roundedData.map((value) => Math.round(value));
  }


  const data = {
    labels: ['Service Orientation', 'Analysis', 'Innovation', 'Planning and Organization',
      'Initiative and Responsibility', 'Coordination', 'Co-operation', 'Quality Control', 'Influence'],
    datasets: [
      {
        label: 'Highest value ',
        data: roundedData,
        backgroundColor: 'rgba(53, 196, 220, 0.2)',
        borderColor: 'rgb(53, 196, 220)',
        borderWidth: 1,
      },
    ]
  };
  return <Radar data={data} options={options} />;
}

export function SalesProcessStrengths ({ salesData }) {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      }
    },
    scales: {
      r: {
        min: 0,
        max: 5,
        ticks: {
          stepSize: 1, // the number of step
        },
        grid: {
          circular: true
        },
        beginAtZero: true
      }
    }
  };

  const data = {
    labels: ['Preparing The Ground', 'Identifying Opportunities', 'Contacting Potential Customers', 'Establishing Relationships',
      'Preparing a Solution', 'Making The Deal', 'Delivering to Promise', 'Ensure Customer Satisfaction'],
    datasets: [
      {
        label: 'Highest value ',
        data: [salesData?.preparing_the_ground, salesData?.identifying_opportunities,
        salesData?.contacting_potential_customers, salesData?.establishing_relationships,
        salesData?.preparing_a_solution, salesData?.making_the_deal,
        salesData?.delivering_to_promise, salesData?.ensure_customer_satisfaction],
        backgroundColor: 'rgba(53, 196, 220, 0.2)',
        borderColor: 'rgb(53, 196, 220)',
        borderWidth: 1,
      },
    ]
  };
  return <Radar data={data} options={options} />;
}
