import React from 'react';
import HubspotForm from "../../components/reusableElementsComponents/HubspotForm";
import * as Constants from '../../constants'
import { useLocation } from 'react-router-dom';
function HelpCenter() {

    const location = useLocation();
    const currentURL = location.pathname;
    
  return (
    <>
        {currentURL !== "/faq-page" &&       <div className="help-center center-content">
    <div style={{width: "45%",
        marginRight: "40px", marginTop : "15px"}}>
        <div className="copy">
            <h3>Get in touch with Apex support team</h3>
        </div>
    </div>
<br/>
<HubspotForm
    portalId={Constants.HUBSPOT_PORTAL_ID}
    formId={Constants.HUBSPOT_CONTACT_USE_FORM_ID}
    target='helpCenterHbForm'
/>
<br/>
<div className='onboarding-page'>
    <div className="onboarding-page-wrapper">
        <div className="copy">
            <h3>Why trust our assessments?</h3>
            <p className="m">
                We have designed and developed Apex Analytics based on the online assessments by world-leading recruitment and human resource development company AON. Their high quality tools are built on a solid foundation of research and psychometrics. AON handles over 30 million talent assessments each year. AON career assessments are used in more than 90 countries and in 40 languages.
            </p>
        </div>
    </div>
</div>
</div>}
    {currentURL === "/faq-page" && 
    <div className='center-content'>
            <div style={{width: "45%",
        marginRight: "40px", marginTop : "50px"}}>
        <div className="copy">
            <h3>Get in touch with Apex support team</h3>
        </div>
    </div>
<br/>
<div style={{width: "50%"}}>
<HubspotForm
    portalId={Constants.HUBSPOT_PORTAL_ID}
    formId={Constants.HUBSPOT_CONTACT_USE_FORM_ID}
    target='helpCenterHbForm'
/>
</div>
    </div>

    }
    </>
  )
}
export default HelpCenter
