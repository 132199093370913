import {memo, useEffect, useState, useMemo, useCallback} from "react";
import TalentDetails from '../../components/talentsComponents/TalentDetails';
import TalentItem from "../../components/talentsComponents/TalentItem";
import * as Constants from "../../constants";
import {useParams, useLocation} from 'react-router-dom'
import B2BApiService from "../../B2bApiService";
import {Select} from "../../components/formComponents/Inputs";
import MazhrDataGrid from "../../components/reusableElementsComponents/MazhrDataGrid";
import loading from '../../assets/images/icons/loading.gif';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {tableColumns} from "../../components/talentsComponents/TalentSearchTableCells"
import TalentProfileFilters from "../../components/talentsComponents/TalentProfileFilters"
import TalentTypeFilters from "../../components/talentsComponents/TalentTypeFilters"
import TeamSimulator from "../../components/teamReviewComponents/teamSimulator";

function Talents() {
    const ITEMS_PER_PAGE = 15;
    const location = useLocation();
    const {hashParam} = useParams();
    const [gridOptionsCheck, setGridOptionsCheck] = useState(true);
    const b2bServiceObj = useMemo(() => {return new B2BApiService();}, []);
    const [pathCheck, setPathCheck] = useState();
    let [filtersKeyArray, setFiltersKeyArray] = useState(Constants.INITIAL_TALENT_SEARCH_FILTER_ARRAY)
    let [multipleIds, setMultipleIds]  = useState()
    let [deleteModalState, setDeleteModalState] = useState(false)
    let [moveModalState, setMoveModalState] = useState(false)
    let [jobIndustries, setJobIndustries] = useState([]);
    let [modalVisibility, setModalVisibility] = useState(false)
    let [selectedIndustry, setSelectedIndustry] = useState(localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_INDUSTRY) ? localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_INDUSTRY) : {
        id: null,
        title: ''
    });
    let [selectedType, setSelectedType] = useState({});
    let [selectedPool, setSelectedPool] = useState({"id": null, "title": null});
    let [selectedTeams, setSelectedTeams] = useState([])
    let [selectedJobs, setSelectedJobs] = useState([]);
    let [matchPercentage,setMatchPercentage]=useState('')
    let [selectedTeamHashes, setSelectedTeamHashes] = useState([])
    let [selectedJobsHashes, setSelectedJobsHashes] = useState([]);

    let [selectedJobProfiles, setSelectedJobProfiles] = useState([]);
    let [selectedTargetProfile, setSelectedTargetProfile] = useState(localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_TARGET_PROFILE) ? localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_TARGET_PROFILE) : {
        id: null,
        title: ''
    });

    let [candidates, setCandidates] = useState([]);
    let [selectedCandidateId, setSelectedCandidateId] = useState(localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_CANDIDATE_ID) ? localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_CANDIDATE_ID) : 0);
    let [selectedCandidateAsEmployee, setSelectedCandidateAsEmployee] = useState(false);
    let [selectedCandidateAsInvitePending, setSelectedCandidateAsInvitePending] = useState(false);

    useEffect(()=> {
        if(selectedJobsHashes.length === 1) {
            setGridOptionsCheck(true)
        }
        else {
            setGridOptionsCheck(false)
        }

    },[selectedJobsHashes])

    const talentItemSelected = useCallback((id) => {
        setSelectedCandidateId(id);
        localStorage.setItem(Constants.LOCAL_STORAGE_KEY_SELECTED_CANDIDATE_ID, id)
    }, [])

    const topMatches = candidates.filter((candidate) => {
        return candidate.match_percentage >= 70;
    })

    const OnSelectTargetProfile = (e) => {
        for (var i = 0; i < selectedJobProfiles.length; i++) {
            if (selectedJobProfiles[i].title === e.target.value) {
                setSelectedTargetProfile(selectedJobProfiles[i]);
                localStorage.setItem(Constants.LOCAL_STORAGE_KEY_SELECTED_TARGET_PROFILE, selectedJobProfiles[i])
            }
        }
    }

    const onSelectJobIndustry = (e) => {
        for (var i = 0; i < jobIndustries.length; i++) {
            if (jobIndustries[i].title === e.target.value) {
                setSelectedIndustry(jobIndustries[i])
                localStorage.setItem(Constants.LOCAL_STORAGE_KEY_SELECTED_INDUSTRY, jobIndustries[i])
                b2bServiceObj.LoadTargetProfilesByIndustry(jobIndustries[i], setSelectedJobProfiles)
                return;
            }
        }
    }

    const onRowSelected = (item) => {
        talentItemSelected(item?.id);
        setSelectedCandidateAsEmployee(item?.hired ? item.hired : false);
        setSelectedCandidateAsInvitePending(item?.pending_invite ? item.pending_invite : false);
        setMatchPercentage(item?.match_percentage)
    }

    useEffect(() => {
        b2bServiceObj.LoadIndustriesList(setJobIndustries)

        if(hashParam){
            b2bServiceObj.LoadPoolByHash(hashParam, setSelectedPool)
            if(location.hash === Constants.URL_WITH_HASH_FOR_TEAM_CHECK){
                setSelectedTeamHashes([hashParam])
                b2bServiceObj.LoadTeamByHash(hashParam,(data) => {
                    setSelectedTeams([data.title])
                })
                setSelectedType({id: Constants.USER_FILTER_TYPE_ONLY_TEAMS, title: "Employee"})
                setCandidates([])
                b2bServiceObj.SearchTalents(
                    setCandidates,
                    Constants.USER_FILTER_TYPE_ONLY_TEAMS,
                    [],
                    [hashParam],
                    null
                )
            }else{
                setSelectedJobsHashes([hashParam])
                b2bServiceObj.LoadPoolByHash(hashParam,(poolData) => {
                    setSelectedJobs([poolData.title])
                    setSelectedIndustry({id : poolData.industry.id, title : poolData.industry.name})
                    b2bServiceObj.LoadTargetProfilesByIndustry(poolData.industry, (data) =>{
                        setSelectedJobProfiles(data)
                        setSelectedTargetProfile({id : poolData.target_profile.id, title : poolData.target_profile.name})
                    })
                })
                setSelectedType({id: Constants.USER_FILTER_TYPE_ONLY_POOLS, title: "Candidate"})
                b2bServiceObj.SearchTalents(
                    setCandidates,
                    Constants.USER_FILTER_TYPE_ONLY_POOLS,
                    [hashParam],
                    [],
                    null
                )
            }

        }
    }, [b2bServiceObj, hashParam, location])

    useEffect(() => {
        setCandidates([])
        b2bServiceObj.SearchTalents(
            setCandidates,
            selectedType.id,
            selectedJobsHashes,
            selectedTeamHashes,
            null
        )
    }, [b2bServiceObj, selectedType, selectedTeamHashes, selectedJobsHashes]);

    useEffect(()=> {
        if(selectedJobsHashes.length === 1) {
            b2bServiceObj.LoadPoolByHash(selectedJobsHashes[0],(poolData) => {
                setSelectedJobs([poolData.title])
                setSelectedIndustry({id : poolData.industry.id, title : poolData.industry.name})
                b2bServiceObj.LoadTargetProfilesByIndustry(poolData.industry, (data) =>{
                    setSelectedJobProfiles(data)
                    setSelectedTargetProfile({id : poolData.target_profile.id, title : poolData.target_profile.name})
                })
            })
        }
                // eslint-disable-next-line
    },[selectedJobsHashes])

    useEffect(() => {
        if (candidates.length > 0) {
            talentItemSelected(candidates[0].id)
            setSelectedCandidateAsEmployee(candidates[0].hired ? candidates[0].hired : false)
            setSelectedCandidateAsInvitePending(candidates[0].pending_invite ? candidates[0].pending_invite : false)
            setMatchPercentage(candidates[0].match_percentage)
        }
    }, [talentItemSelected, candidates])

    useEffect(() => {
        let targetProfileId = selectedTargetProfile.id ? selectedTargetProfile.id : null;

        if(selectedType.hasOwnProperty('id')){
            b2bServiceObj.SearchTalents(
                setCandidates,
                selectedType.id,
                selectedJobsHashes,
                selectedTeamHashes,
                targetProfileId,
                filtersKeyArray
            )
        }
    }, [b2bServiceObj, selectedTargetProfile, selectedTeams, selectedJobs, selectedTeamHashes, selectedJobsHashes, filtersKeyArray, selectedType])

    return (
        <div className="page-wrapper">
            <div className="bg-cover">
                <div className="filters section m-0">
                    <div className="custom-filters" style={{justifyContent: "start"}}>

                        <TalentTypeFilters
                            selectedType={selectedType}
                            setSelectedType={setSelectedType}
                            setGridOptionsCheck = {setGridOptionsCheck}
                            selectedTeams={selectedTeams}
                            setSelectedTeams={setSelectedTeams}
                            selectedJobs={selectedJobs}
                            setSelectedJobs={setSelectedJobs}
                            selectedTeamHashes={selectedTeamHashes}
                            setSelectedTeamHashes = {setSelectedTeamHashes}
                            selectedJobHashes={selectedJobsHashes}
                            setSelectedJobHashes={setSelectedJobsHashes}
                        />

                        <div style={{borderLeft: "1px solid grey"}}>
                            {
                                <div className="target-position-simple d-flex "
                                     style={{display: "flex", flexDirection: "column", marginLeft: "40px"}}>
                                    <div className="input">
                                        {(jobIndustries && jobIndustries?.length > 0 && selectedIndustry) ?
                                            <Select
                                                id={'target_profile'}
                                                label='Job Departments'
                                                options={jobIndustries}
                                                handleChange={onSelectJobIndustry}
                                                option={selectedIndustry?.title}
                                                defaultValue={"Select job department"}
                                            /> :
                                            <img width="40" height="40" src={loading} alt="Loading..."/>
                                        }
                                    </div>

                                    <div className="input">
                                        {selectedJobProfiles && selectedJobProfiles?.length > 0 ?
                                            <Select
                                                id={'target_profile'}
                                                label='Target Profiles'
                                                options={selectedJobProfiles}
                                                handleChange={OnSelectTargetProfile}
                                                option={selectedTargetProfile.title}
                                                defaultValue={"Select target profile"}
                                            /> :
                                            selectedIndustry && selectedIndustry?.id &&
                                            <img width="40" height="40" src={loading} alt="Loading..."/>
                                        }
                                    </div>
                                </div>

                            }
                        </div>

                        {selectedType && selectedType?.id !== null && <div style={{borderLeft: "1px solid grey", marginLeft: "30px", width : "100%" }}>
                            <TalentProfileFilters
                                filtersKeyArray={filtersKeyArray}
                                setFiltersKeyArray={setFiltersKeyArray}
                            />
                        </div>}
                    </div>
                </div>

                <div className='desktop-layout d-none d-lg-block m-0'>
                    {candidates && candidates?.length === 0 && JSON.stringify(selectedTargetProfile) !== JSON.stringify({id: null, title: ''}) &&
                    <div className="talents p-20 mt-20 ml-7 mt-20">
                        <p>No candidates found.</p>
                    </div>
                    }

                    {candidates && candidates?.length !== 0 &&
                    <>
                        <div className="matches-actions">
                            <p className="talents-number bold s"> {topMatches?.length + ' perfect'} matches out
                                of {candidates?.length} candidates</p>
                        </div>

                        <div className="talents p-0">
                            <MazhrDataGrid
                                rows={candidates}
                                pageSize={ITEMS_PER_PAGE}
                                gridOptionsCheck={gridOptionsCheck}
                                pathCheck={pathCheck}
                                setPathCheck={setPathCheck}
                                setMultipleIds={setMultipleIds}
                                setDeleteModalState={setDeleteModalState}
                                setMoveModalState={setMoveModalState}
                                columns={tableColumns}
                                checkboxSelection={true}
                                onRowSelectionModelChange={onRowSelected}
                            />


                            {(selectedCandidateId !== 0 || selectedCandidateId !== null || selectedIndustry !== null) ?
                                <TalentDetails
                                    candidateId={selectedCandidateId}
                                    industryId={selectedIndustry.id}
                                    hashParam = {selectedJobsHashes}
                                    targetProfileId={selectedTargetProfile.id}
                                    asEmployee={selectedCandidateAsEmployee}
                                    deleteModalState={deleteModalState}
                                    setDeleteModalState={setDeleteModalState}
                                    setMoveModalState={setMoveModalState}
                                    pathCheck={pathCheck}
                                    setPathCheck={setPathCheck}
                                    moveModalState={moveModalState}
                                    multipleIds={multipleIds}
                                    selectedJobs={selectedJobs}
                                    pendingInvite={selectedCandidateAsInvitePending}
                                    hash={selectedPool?.hash !== undefined ? selectedPool.hash : selectedPool?.id}
                                    candidate={candidates.find(data => data.id === selectedCandidateId)}
                                    matchPercentage={matchPercentage}
                                />
                                :
                                <TalentDetails
                                    candidateId={candidates[0].id}
                                    industryId={selectedIndustry.id}
                                    hashParam = {selectedJobsHashes}
                                    targetProfileId={selectedTargetProfile.id}
                                    asEmployee={selectedCandidateAsEmployee}
                                    deleteModalState={deleteModalState}
                                    setDeleteModalState={setDeleteModalState}
                                    setMoveModalState={setMoveModalState}
                                    pathCheck={pathCheck}
                                    setPathCheck={setPathCheck}
                                    multipleIds={multipleIds}
                                    moveModalState={moveModalState}
                                    selectedJobs={selectedJobs}
                                    pendingInvite={selectedCandidateAsInvitePending}
                                    hash={selectedPool?.hash !== undefined ? selectedPool.hash : selectedPool?.id}
                                    candidate={candidates[0]}
                                    matchPercentage={candidates[0].match_percentage}
                                />
                            }
                        </div>
                    </>
                    }
                </div>

                {window.innerWidth < 768 && <div className="mobile-layout d-lg-none">
                    {candidates.map((candidate, index) => {
                        return (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id={index}
                                >
                                    <TalentItem data={candidate} key={index} selectedCandidateId={selectedCandidateId}
                                                onSelectedFunction={talentItemSelected}/>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {(selectedCandidateId !== 0 || selectedCandidateId !== null || selectedIndustry !== null) ?
                                        <TalentDetails
                                            candidateId={selectedCandidateId}
                                            hashParam = {selectedJobsHashes}
                                            asEmployee={selectedCandidateAsEmployee}
                                            industryId={selectedIndustry.id}
                                            targetProfileId={selectedTargetProfile.id}
                                            hash={selectedPool?.hash !== undefined ? selectedPool.hash : selectedPool?.id}
                                            matchPercentage={matchPercentage}
                                        />
                                        :
                                        <TalentDetails
                                            candidateId={candidates[0].id}
                                            industryId={selectedIndustry.id}
                                            hashParam = {selectedJobsHashes}
                                            asEmployee={selectedCandidateAsEmployee}
                                            targetProfileId={selectedTargetProfile.id}
                                            hash={selectedPool?.hash !== undefined ? selectedPool.hash : selectedPool?.id}
                                            candidate={candidates[0]}
                                            matchPercentage={candidates[0].match_percentage}
                                        />
                                    }
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>}
            </div>
            <div className="picklist-div" >
                <TeamSimulator modalVisbility={modalVisibility} setModalVisibility={setModalVisibility}></TeamSimulator>
            </div>
        </div>
    )
}

export default memo(Talents)




